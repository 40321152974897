import * as Container from "../../../../src/components/container.js";
import * as Section from "../../../../src/components/splitSections.js";
import { Button } from "../../../../src/components/button.js";
import Editoria from "../../../../src/components/editoria.js";
import * as React from 'react';
export default {
  Container,
  Section,
  Button,
  Editoria,
  React
};