import React from "react"
import {StaticImage} from "gatsby-plugin-image"
import styled from "styled-components"

import {ImageSliderFade} from "./imageSliderFade"

const StyledContainerFluid = styled.div`    
    background-color: ${props => props.background ? props.background : `transparent`}; 
    position: relative;
    
    & .background-image {
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    
    & .content {
        z-index: 10;
    }
    
    h1, h2 {
        color: white;
    }
`;

const Hero = ({
                  backgroundColor,
                  textColor,
                  className,
                  children,
                  id,
                  style,
                  h1,
                  h1Class,
                  h2,
                  h2Class,
                  imageSlider,
                  image,
                  classicHero,
                  contentPadding,
                  heroImage
             }) => {
    return (
        <>
        {classicHero ?

            <StyledContainerFluid id={id} background={backgroundColor}>
                { heroImage.includes("hero_home") && <StaticImage src="../images/hero_home.jpg" alt="" style={{position: "absolute"}} className="background-image"/> }
                { heroImage.includes("hero_team") && <StaticImage src="../images/hero_team.jpg" alt="" style={{position: "absolute"}} className="background-image"/> }
                { heroImage.includes("hero_editoria") && <StaticImage src="../images/hero_editoria.jpg" alt="" style={{position: "absolute"}} className="background-image"/> }
                <div className={"container"} >

                    <div className={`row  ${className ? className : ''}`} style={style}>
                        <div className={`content col-12 py-${contentPadding ? contentPadding : '5'}`} style={{textAlign: "left"}}>
                            <h1 className={`pt-${contentPadding ? contentPadding : '5'}`}>{h1}</h1>
                            <h2 className={`pb-${contentPadding ? contentPadding : '5'}`}>{h2}</h2>
                        </div>
                    </div>
                </div>
            </StyledContainerFluid>

            :
            <StyledContainerFluid id={id} background={backgroundColor}>
                <div className={"container"} style={{maxWidth: "unset"}}>
                    <section className={`row justify-content-center ${className ? className : ''}`} style={style}>
                        {/*small screen*/}
                        {imageSlider &&
                        <div className={`col-12 px-0 d-lg-none`}>
                            <ImageSliderFade />
                        </div>
                        }
                        {/*small screen*/}
                        {image &&
                        <div className={`col-12 px-0 d-lg-none`}>
                            { heroImage.includes("hero_home") && <StaticImage src="../images/hero_home.jpg" alt="" style={{position: "absolute"}} className="background-image"/> }
                            { heroImage.includes("hero_team") && <StaticImage src="../images/hero_team.jpg" alt="" style={{position: "absolute"}} className="background-image"/> }
                            { heroImage.includes("hero_editoria") && <StaticImage src="../images/hero_editoria.jpg" alt="" style={{position: "absolute"}} className="background-image"/> }
                        </div>
                        }
                        {/*any screen size*/}
                        <div className={`col-12 col-sm-11 col-md-10 col-lg-6 justify-content-center align-self-center px-3 px-lg-5`}>
                            <h1 className={`${h1Class ? h1Class+'  pt-4 px-1' : 'pt-4 px-1'}`} style={{opacity: ".9"}}>
                                {h1}
                            </h1>
                            <h2 className={`${h2Class ? h2Class+' mx-auto pb-4 px-1' : 'pb-4 px-1'}`} style={{opacity: ".9"}}>
                                {h2}
                            </h2>
                        </div>
                        {/*bigger screen*/}
                        <div className={`col-lg-6 justify-content-center align-self-center d-none d-lg-block px-lg-0`}>

                            {imageSlider &&
                            <ImageSliderFade />
                            }

                            {image &&
                            <div className={`col-12 px-0`}>
                                { heroImage.includes("hero_home") && <StaticImage src="../images/hero_home.jpg" alt="" style={{position: "absolute"}} className="background-image"/> }
                                { heroImage.includes("hero_team") && <StaticImage src="../images/hero_team.jpg" alt="" style={{position: "absolute"}} className="background-image"/> }
                                { heroImage.includes("hero_editoria") && <StaticImage src="../images/hero_editoria.jpg" alt="" style={{position: "absolute"}} className="background-image"/> }
                            </div>
                            }

                        </div>

                        {children}

                    </section>
                </div>
            </StyledContainerFluid>
        }
        </>
    );
};

export {Hero};