import React from "react"

import Anchor from "./anchor"
import {jsonConfigSito, useFetch} from "../utils/config"


const Partners = () => {
    const { data } = useFetch(jsonConfigSito, {});
    const { partners: { lista } = { lista: [] } } = data;
    return (
        <>
            {lista.map((e, i) => {
                return (
                    <div key={i} className="py-2 py-lg-3">
                        <h4 className="h5 mb-0" style={{fontSize: "1.3rem"}}>{e.nome}</h4>
                        {(e.link !== undefined && e.link !== "") &&
                        <Anchor external href={e.link} title="Vai al sito" style={{fontSize: "1rem"}}>{e.link}</Anchor>
                        }
                    </div>
                );
            })}
        </>
    )
};

export default Partners