import React, {useState} from "react"
import styled from "styled-components"

const StyledText = styled.span`
    cursor: pointer;
    text-decoration: underline;
`;

const ReadMore = ({text, className, children}) => {

    const [clicked, setClicked] = useState(false);

    return (
        <>
            {!clicked  &&
                <StyledText className={className} onClick={() => setClicked(true)}>{text}</StyledText>
            }
            {clicked  &&
                <span className={className}>
                {children}
                </span>
            }
        </>
    )
};

export {ReadMore};