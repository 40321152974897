import React from "react"
import styled, {css} from "styled-components"

const StyledContainerFixed = styled.div`    
    background-color: ${props => props.background ? props.background : `transparent`};
    color: ${props => props.color ? props.color : `inherit`}; 
    hr {
        border-bottom: 1px solid ${props => props.color ? props.color : `inherit`};
    }
`;

const StyledContainerFluid = styled.div`    
    background-color: ${props => props.background ? props.background : `transparent`};   
    color: ${props => props.color ? props.color : `inherit`};      
    
    hr {
        border-bottom: 1px solid ${props => props.color ? props.color : `inherit`};
    }
    ${props => props.color && css` 
        hr {
            border-bottom: 1px solid ${props => props.color ? props.color : `inherit`};
        }
    `}
`;

const Fixed = ({ background, color, className, children, id, style }) => (

    <StyledContainerFixed id={id} background={background} color={color} className={`container`} style={style}>
        <section className={`row ${className ? className : ''}`}>
            {children}
        </section>
    </StyledContainerFixed>

);

const Fluid = ({ background, color, className, children, id, style }) => (

    <StyledContainerFluid id={id} background={background} color={color} style={style}>
        <div className={"container"}>
            <section className={`row ${className ? className : ''}`}>
                {children}
            </section>
        </div>
    </StyledContainerFluid>

);

const FullWidth = ({ background, color, className, children, id, style }) => (

    <StyledContainerFluid id={id} background={background} color={color} style={style}>
        <div className={"container-fluid"}>
            <section className={`row ${className ? className : ''}`}>
                {children}
            </section>
        </div>
    </StyledContainerFluid>

);

export {Fixed, Fluid, FullWidth};