import React from 'react'
import {StaticImage} from "gatsby-plugin-image"
import { Fade } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

const fadeProperties = {
    duration: 2500,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
};

const ImageSliderFade = () => {
    return (
        <>
            <Fade {...fadeProperties}>
                <div className="each-fade">
                    <div className="image-container">
                        <StaticImage src="../images/hero_home.jpg" alt="test" style={{width: "100%", height: "100%", objectFit: "cover"}}/>
                    </div>
                </div>
                <div className="each-fade">
                    <div className="image-container">
                        <StaticImage src="../images/image05.jpg" alt="test" style={{width: "100%", height: "100%", objectFit: "cover"}} />
                    </div>
                </div>
            </Fade>

        </>
    )
};

export {ImageSliderFade};