import React from 'react'

const One = ({children, order, col, className, style}) => (
    <div order={order} col={col} className={`col-lg-${col ? col : "6"} ${className ? className : ''} justify-content-center align-self-center ${order === "last" ? `order-lg-last` : ''}`} style={style}>
        {children}
    </div>
);

const Two = ({children, order, col, className, style}) => (
    <div order={order} col={col} className={`col-lg-${col ? col : "6"} ${className ? className : ''} justify-content-center align-self-center ${order === "first" ? `order-lg-first` : ''}`} style={style}>
        {children}
    </div>
);

const FullWidth = ({children, order, col, className, style}) => (
    <div order={order} col={col} className={`col-lg-${col ? col : "12"} ${className ? className : ''} justify-content-center align-self-center`} style={style}>
        {children}
    </div>
);

export {One, Two, FullWidth};