import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faShare, faArrowAltCircleRight, faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import * as Container from "../../../../src/components/container.js";
import * as Section from "../../../../src/components/splitSections.js";
import Card from "../../../../src/components/card.js";
import { Button } from "../../../../src/components/button.js";
import { ReadMore } from "../../../../src/components/readMore";
import Anchor from "../../../../src/components/anchor.js";
import ImageGallery from "../../../../src/components/imageGallery.js";
import Contatti from "../../../../src/components/contatti.js";
import Partners from "../../../../src/components/partners.js";
import * as React from 'react';
export default {
  useStaticQuery,
  graphql,
  FontAwesomeIcon,
  faFilePdf,
  faShare,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  Container,
  Section,
  Card,
  Button,
  ReadMore,
  Anchor,
  ImageGallery,
  Contatti,
  Partners,
  React
};