import {useEffect, useState} from "react"

export const jsonConfigSito = "https://zenitsecurity.s3.eu-south-1.amazonaws.com/websites/archeosit/archeoSIT_config_sito.json";
export const jsonConfigTeam = "https://zenitsecurity.s3.eu-south-1.amazonaws.com/websites/archeosit/archeoSIT_config_team.json";
export const jsonConfigEditoria = "https://zenitsecurity.s3.eu-south-1.amazonaws.com/websites/archeosit/archeoSIT_config_editoria.json";

export const useFetch = (url, options = {}) => {

    const [data, setData] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ status: false, message: '' });

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const response = await fetch(url, options);
                if (!response.ok) throw new Error('Error fetching data.');
                const json = await response.json();
                setData(json);
                setLoading(false);
            } catch (error) {
                setError({ status: true, message: error.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { data, loading, error };

};

