import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "../../../../src/components/hero.js";
import * as Container from "../../../../src/components/container.js";
import * as Section from "../../../../src/components/splitSections.js";
import { Button } from "../../../../src/components/button.js";
import Team from "../../../../src/components/team.js";
import * as React from 'react';
export default {
  StaticImage,
  Hero,
  Container,
  Section,
  Button,
  Team,
  React
};