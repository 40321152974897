import React from "react"
import {graphql, StaticQuery} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image"
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";

const ImageGallery = ({bootstrapCol}) => {
    const options = {
        settings: {
            transitionSpeed: 900,
            disablePanzoom: true
        },
        caption: {
            showCaption: false
        },
        buttons: {
            showDownloadButton: false
        }
    };

    return(
        <StaticQuery
            query={graphql`
              query {
                gallery:allFile(
                  filter: {relativeDirectory: {eq: "gallery"}}
                  sort: {fields: base, order: ASC}
                ){
                totalCount
                edges {
                  node {
                    id
                    base
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        transformOptions: {fit: COVER}
                        placeholder: BLURRED
                        webpOptions: {quality: 70}
                      )
                    }
                  }
                }
              }
            }
            `}
            render={data => {
                // console.log(data.gallery.totalCount);
                return (
                    <SimpleReactLightbox>
                        <SRLWrapper options={options}>
                            <div className={"row g-1 py-3"}>
                                {data.gallery.edges.map(({node}) => (
                                    <div key={node.id} className={`${bootstrapCol ? "col-"+bootstrapCol : "col-3"} justify-content-center align-self-center`}>
                                        <a href={node.publicURL} title={"Ingrandisci l'immagine"}>
                                            <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base.split('_').join(' ').split('.')[0]} />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </SRLWrapper>
                    </SimpleReactLightbox>
                )
            }}
        />
    )

};

export default ImageGallery;