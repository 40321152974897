const theme = {
    font: {
        primary: `'Source Sans Pro', Arial, Helvetica, sans-serif`,
        secondary: `'Faustina', serif`,
        thin: `100`,
        extralight: `200`,
        light: `300`,
        normal: `400`,
        medium: `500`,
        semibold: `600`,
        bold: `700`,
        extrabold: `900`,
    },
    font_size: {
        xxxsmall: "font-size: 0.7rem; line-height: 1.1rem;",
        xxsmall: "font-size: 0.8rem; line-height: 1.2rem;",
        xsmall: "font-size: 0.938rem; line-height: 1.2rem;",
        small: "font-size: 1rem; line-height: 1.6rem;",
        regular: "font-size: 1.15rem; line-height: 1.6rem;",
        large: "font-size: 1.6rem; line-height: 2.1rem;",
        larger: "font-size: 2.2rem; line-height: 2.8rem;",
        xlarge: "font-size: 2.75rem; line-height: 3.2rem;",
    },
    color: {
        black: "#050401",
        white: "#FFFFFF",
        babypowder: "#F7F7F2",
        orange: "#d59f59",
        rust: "#A9461D",
        ming: "#2C7784"
    },
    screen: {
        xs: "575px",
        sm: "767px",
        md: "992px",
        lg: "1200px",
    },
    header: {
        height: "80px"
    }
};

export default theme