import React from "react"
import  {StaticImage } from "gatsby-plugin-image"
import { SocialIcon } from 'react-social-icons'

import { Button } from "./button"
import {jsonConfigTeam, useFetch} from "../utils/config"

const Team = () => {
    const { data } = useFetch(jsonConfigTeam, {});
    const { team: { lista } = { lista: [] } } = data;

    return (
        <>
            {lista.map((e, i) => {
                return (
                    <div key={i} className="col-lg-6 pt-3 pb-5">
                        <div className="row">
                            <div className="col-lg-6 col-xl-4">
                                {(e.img && e.img !== "") ?
                                    <>
                                        <img className="profile-img img-fluid mx-auto mx-md-0 d-block d-md-inline-block mb-2 mb-lg-0" src={e.img} alt={e.nome} />
                                    </>
                                    :
                                    <>
                                        <StaticImage className="profile-img img-fluid mx-auto mx-md-0 d-block d-md-inline-block mb-2 mb-lg-0" src="../images/profile.jpg" alt={e.nome} />
                                    </>
                                }
                            </div>
                            <div className="col-lg-6 col-xl-8 justify-content-center align-self-center">
                                <h4 className="h5 text-center text-md-left">{e.nome}</h4>
                                <hr/>
                                {(e.ruolo && e.ruolo !== "") &&
                                    <h5 className="h6">{e.ruolo}</h5>
                                }
                                {(e.info && e.info !== "") &&
                                    <p>{e.info}</p>
                                }
                                {(e.cv && e.cv !== "") &&
                                    <Button targetBlank small inline color={"#2C7784"} hrefJSON={e.cv} title="Clicca qui per scaricare il pdf">
                                        Scarica il CV
                                    </Button>
                                }
                                <span className="">
                                {(e.instagram && e.instagram !== "") &&
                                    <SocialIcon url={e.instagram}
                                                bgColor="#2C7784"
                                                className="me-1"
                                                network="instagram"
                                                title={`Vai al profilo Instagram di ${e.nome}`}
                                                style={{maxWidth: "40px", maxHeight: "40px"}}
                                                target={"_blank"} rel="noopener noreferrer"
                                    />
                                }
                                {(e.facebook && e.facebook !== "") &&
                                    <SocialIcon url={e.facebook}
                                            bgColor="#2C7784"
                                            className="me-1"
                                            network="facebook"
                                            title={`Vai al profilo Facebook di ${e.nome}`}
                                            style={{maxWidth: "40px", maxHeight: "40px"}}
                                            target={"_blank"} rel="noopener noreferrer"
                                    />
                                }
                                {(e.twitter && e.twitter !== "") &&
                                    <SocialIcon url={e.twitter}
                                                bgColor="#2C7784"
                                                className="me-1"
                                                network="twitter"
                                                title={`Vai al profilo Twitter di ${e.nome}`}
                                                style={{maxWidth: "40px", maxHeight: "40px"}}
                                                target={"_blank"} rel="noopener noreferrer"
                                    />
                                }
                                {(e.linkedin && e.linkedin !== "") &&
                                    <SocialIcon url={e.linkedin}
                                                bgColor="#2C7784"
                                                className="me-1"
                                                network="linkedin"
                                                title={`Vai al profilo LinkedIn di ${e.nome}`}
                                                style={{maxWidth: "40px", maxHeight: "40px"}}
                                                target={"_blank"} rel="noopener noreferrer"
                                    />
                                }
                                {(e.email && e.email !== "") &&
                                <SocialIcon url={"mailto:"+e.email}
                                            bgColor="#2C7784"
                                            className="me-1"
                                            network="email"
                                            title={`Invia una email a ${e.nome}`}
                                            style={{maxWidth: "40px", maxHeight: "40px"}}
                                />
                                }
                                {(e.sito && e.sito !== "") &&
                                    <SocialIcon url={e.sito}
                                                bgColor="#2C7784"
                                                title={`Visita il sito web di ${e.nome}`}
                                                label={`Sito web di ${e.nome}`}
                                                style={{maxWidth: "40px", maxHeight: "40px"}}
                                                target={"_blank"}
                                    />
                                }
                                </span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    )
};

export default Team