import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faExternalLinkAlt, faExternalLinkSquareAlt} from "@fortawesome/free-solid-svg-icons"
import {Link} from "gatsby";
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Anchor = ({ href, title, children, info, modal, internal, external, style }) => (
    <span>
        {internal &&
        <Link to={href} style={style} title={title}>
            {children}
            <FontAwesomeIcon icon={faExternalLinkSquareAlt} className={"ms-1"} style={{width: "13px"}} />
        </Link>
        }
        {info &&
        <AnchorLink to={href} stripHash style={style} className="stripped" title={title}>
            {children}
            <FontAwesomeIcon icon={faInfoCircle} className={"ms-1"} style={{width: "18px"}} />
        </AnchorLink>
        }
        {(!internal && !info) &&
        <a target={"_blank"} rel="noopener noreferrer" href={href} style={style} title={title}>
            {children}
            {modal && <FontAwesomeIcon icon={faExternalLinkAlt} className={"ms-1"} style={{width: "13px"}} />}
            {external && <FontAwesomeIcon icon={faExternalLinkAlt} className={"ms-1"} style={{width: "13px"}} />}
        </a>
        }
    </span>
);

export default Anchor;