import React from "react";

import {jsonConfigSito, useFetch} from "../utils/config"

const Contatti = () => {
    const { data } = useFetch(jsonConfigSito, {});
    const { contatti: { lista } = { lista: [] } } = data;
    return (
        <>
            {lista.map((e, i) => {
                return (
                    <div key={i}>
                        <hr />
                        <h4 className="h5">{e.nome}</h4>
                        <p className="mb-1">{e.indirizzo}</p>
                        {(e.tel !== undefined && e.tel !== "") &&
                            <p>Tel: {e.tel}</p>
                        }
                        {(e.email !== undefined && e.email !== "") &&
                        <p className="mb-4">Email: <a href={`mailto:${e.email}`} title="Invia una email">{e.email}</a></p>
                        }
                    </div>
                );
            })}
        </>
    )
};

export default Contatti