import React, {useEffect, useState} from "react"
import ReactPaginate from 'react-paginate'
import { navigate } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import styled from "styled-components"

import {Button} from "./button";
import {jsonConfigEditoria, useFetch} from "../utils/config";

const MyPaginate = styled(ReactPaginate).attrs({
    activeClassName: 'active',
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 1rem;
  li a {
    border-radius: 0;
    padding: .5rem 1rem;
    // border: 1px solid #b8b8b8;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #A9461D;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const StyledCard = styled.div`
    width: 100%;
    background-color: ${props => props.theme.color.white};    
    
    box-shadow: 0 20px 25px -5px rgba(0,0,0,.1), 0 10px 10px -5px rgba(0,0,0,.04);
    padding: ${props => props.withSections === true ? `0 20px` : `20px`};
    margin-bottom: ${props => props.withSections === true ? `2rem` : `0`};
    
    @media (min-width: 576px) {    
        border-radius: 8px;
    }
    
    & & h1, h2, h3, h4, h5 {
        margin: 0;
    }
    
    & h4 {
        font-size: 1.4rem;
        line-height: 1.9rem;
        font-family: 'Source Sans Pro',Arial,Helvetica,sans-serif;
        font-weight: 500;
        margin: .8rem 0 .8rem 0;
    }
    
    & p {
        margin: 15px 0;
        ${props => props.theme.font_size.small};
    }
    
    & hr {
        margin: 0;
    }
    
    & .img-thumbnail {
        padding: 0.25rem;
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
        max-width: 100%;
        height: auto;
    }
    
    & .key {
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 600;
        color: #A94612;
    }
    
    & .descrizione {
        padding: 1rem 0;
    }
    
    & button {
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 600;
    }       
`;

const Editoria = ({ itemsPerPage }) => {
    const { data } = useFetch(jsonConfigEditoria, {});
    const { editoria: { lista } = { lista: [] } } = data;
    // console.log(lista);

    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(lista.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(lista.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, data]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % lista.length;
        // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
        // scroll to top of first item in list
        navigate('#lista_editoria')
        // console.log(window.location.hash)
    };

    // Remove # from url
    useEffect(() => {
        const hashtag = window.location.hash;
        if (hashtag) {
            const currentURL = window.location.href;
            const newURL = currentURL.split("#")[0];
            window.history.replaceState("", "", newURL)
        }
    });

    return (
        <>
            <div id="lista_editoria" className="items px-0">
                {currentItems && currentItems.map((e, i) => {
                    return (
                    <StyledCard className="my-2" key={i+"div"}>
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-2 justify-content-center align-self-center">
                                {e.url_img ?
                                    <img src={e.url_img} className="img-thumbnail" alt={e.titolo} />
                                :
                                    <StaticImage src="../images/placeholder.jpg" alt={e.titolo} />
                                }
                            </div>
                            <div className="col-12 col-md-8 col-lg-10 justify-content-center align-self-center">
                                <h4 className="d-inline-block">{e.titolo}</h4>
                                <hr />
                                {e.info &&
                                <div className="row">
                                    <div className="col-12 py-2">
                                        <div className="row">
                                        { e.info.map((e, index) => (
                                            e.key !== "descrizione" && !e.val.includes("http") && !e.val.includes("https") &&
                                            <div className="col-lg-6" key={index+"_info"+e.val}>
                                                <p className="m-0"><span className="key">{e.key}:</span> {e.val}</p>
                                            </div>
                                        ))
                                        }
                                        </div>
                                        { e.info.map((e, index) => (
                                            e.key === "descrizione" &&
                                                <p className="m-0 descrizione" key={index+"_descr"+e.val}><span className="key">{e.key}:</span> {e.val}</p>
                                        ))}
                                        { e.info.map((e, index) => (
                                            (e.val.includes("http") || e.val.includes("https")) &&
                                            <Button inline small targetBlank href={e.val} color={"#A9461D"} className="mt-1 mt-sm-3 mb-sm-2" key={index+"_link"+e.val}>
                                                {e.key}
                                            </Button>
                                        ))}
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </StyledCard>
                )
                })}
            </div>
            <MyPaginate
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextLabel=">"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </>
    )
};

export default Editoria